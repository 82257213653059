import { Button, Form, Image, Modal, Spinner } from "react-bootstrap";
import { useContext, useState } from "react";

import { AuthContext } from "../helpers/Contexts";
import { StyledDropzone } from "../helpers/StyledDropzone";
import { postDataWithFiles } from "../helpers/Data";
import { toast } from "react-toastify";

export default function CreateTicketModal({ getTickets }: { getTickets: () => Promise<void> }) {
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const context = useContext(AuthContext);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setTitle("");
    setMessage("");
    setFiles([]);
    setLoading(false);
    setShow(true);
  };

  const createTicket = async () => {
    setLoading(true);

    const body = {
      CompanyId: (context.selectedCompany as any).id,
      Message: message,
      Title: title,
    };

    const res = await postDataWithFiles("tickets", body, files);

    if (res.ok) {
      await getTickets();
      handleClose();
    } else {
      toast.error("Could not create ticket. Please try again");
    }

    setLoading(false);
  };

  const handleDeleteFile = (fileVal: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileVal));
  };

  const isImage = (file: File) => {
    return file.type.startsWith("image/");
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Request Support
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create a Support Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Title</Form.Label>
            <Form.Control isInvalid={title === ""} value={title} placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" isInvalid={message === ""} value={message} placeholder="Enter Message " onChange={(e) => setMessage(e.target.value)} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Attachments</Form.Label>
            <StyledDropzone fileUploadCallback={(e: any) => setFiles([...files, ...e])} />
          </Form.Group>

          <div style={{ marginTop: "20px" }}>
            {files.map((file, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  marginBottom: "10px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  {isImage(file) && (
                    <Image src={URL.createObjectURL(file)} alt={file.name} thumbnail style={{ width: "80px", height: "80px", objectFit: "cover" }} />
                  )}
                  <span>{file.name}</span>
                </div>

                <Button variant="danger" size="sm" onClick={() => handleDeleteFile(file)}>
                  Delete
                </Button>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={createTicket} disabled={title === "" || message === "" || loading}>
            {loading ? <Spinner animation="grow" size="sm" /> : "Request Support"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
