import { Badge } from "react-bootstrap";
import { ETicketStatus } from "../entities/Ticket";

interface StatusTagProps {
  status: ETicketStatus;
}

export const getStatusText = (status: ETicketStatus): string => {
  switch (status) {
    case ETicketStatus.New:
      return "New";
    case ETicketStatus.Open:
      return "Open";
    case ETicketStatus.PendingCustomer:
      return "Pending Customer Response";
    case ETicketStatus.InProgress:
      return "In Progress";
    case ETicketStatus.Closed:
      return "Closed";
    case ETicketStatus.Cancelled:
      return "Cancelled";
    default:
      return "Unknown";
  }
};

const TicketStatusTag: React.FC<StatusTagProps> = ({ status }) => {
  // Determine badge variant based on status
  const getBadgeVariant = (status: ETicketStatus): string => {
    switch (status) {
      case ETicketStatus.New:
        return "primary"; // Blue for new tickets
      case ETicketStatus.Open:
        return "success"; // Green for open tickets
      case ETicketStatus.PendingCustomer:
        return "warning"; // Yellow for pending customer response
      case ETicketStatus.InProgress:
        return "info"; // Teal for in-progress tickets
      case ETicketStatus.Closed:
        return "secondary"; // Grey for closed tickets
      case ETicketStatus.Cancelled:
        return "danger"; // Red for cancelled tickets
      default:
        return "light"; // Default light grey
    }
  };

  // Map enum to readable string

  return (
    <Badge variant={getBadgeVariant(status)} style={{ fontSize: "0.9em" }}>
      {getStatusText(status)}
    </Badge>
  );
};

export default TicketStatusTag;
