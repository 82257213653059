import { Button, Col, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { ETicketStatus, ITicketListViewModel } from "../entities/Ticket";
import TicketStatusTag, { getStatusText } from "../helpers/ticketStatusBadge";
import { getData, getFileUrl, postDataWithFiles } from "../helpers/Data";
import { useContext, useEffect, useRef, useState } from "react";

import { AuthContext } from "../helpers/Contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { toast } from "react-toastify";

var loaded = false;

export default function ViewTicketModal({
  history,
  location,
  ticketId,
  getTickets,
}: {
  history: any;
  location: any;
  ticketId: any;
  getTickets: () => Promise<void>;
}) {
  const [show, setShow] = useState(false);
  const [ticket, setTicket] = useState<ITicketListViewModel | null>(null);
  const [loading, setLoading] = useState(false);
  const context = useContext(AuthContext);
  const [newMessage, setNewMessage] = useState<string>("");
  const [fileUrls, setFileUrls] = useState<Record<string, string>>({});
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const hiddenFileInput = useRef(null);
  const [edited, setEdited] = useState(false);
  const selectOptions = (Object.keys(ETicketStatus) as Array<keyof typeof ETicketStatus>)
    .filter((el) => {
      return isNaN(Number(el));
    })
    .map((value, index) => ({
      label: getStatusText(index),
      value: index,
    })) as any;

  const handleClose = () => {
    setFileUrls({});
    setSelectedFiles([]);
    setNewMessage("");
    setShow(false);
  };
  const supportId = new URLSearchParams(location.search).get("id") || null;

  useEffect(() => {
    if (supportId && !loaded) {
      loaded = true;
      console.log("ticket Id", supportId);
      handleShow();
      history.push(`/support`);
    }
  }, []);

  const updateTicketStatus = async () => {
    setLoading(true);

    if (!ticket) return;

    const res = await postDataWithFiles("tickets", ticket, []);

    if (res.ok) {
      await getTickets();
      handleClose();
    } else {
      toast.error("Could not create ticket. Please try again");
    }

    setLoading(false);
  };

  const handleShow = async () => {
    setLoading(true);
    await fetchTicket();
    setShow(true);
    setLoading(false);
  };

  const fetchTicket = async () => {
    setEdited(false);
    try {
      const res = await getData(`tickets/${supportId ?? ticketId}`);
      setTicket(res);
      await fetchFileUrls(res);
    } catch (e) {
      toast.error("Could not open ticket. Please try again");
    }
  };

  let setField = (value: any, field: keyof ITicketListViewModel) => {
    setEdited(true);
    let val = ticket;
    (val as any)![field] = value;
    setTicket({ ...(val as any) });
  };

  const fetchFileUrls = async (ticketVal: ITicketListViewModel) => {
    const urls: any = [];
    for (const message of ticketVal.ticketMessages) {
      for (const fileMapping of message.messageFileMappings) {
        if (!fileUrls[fileMapping.fileId]) {
          try {
            const url = await getFileUrl(fileMapping.fileId);
            await fetch(url)
              .then((res) => res.blob())
              .then((e) => {
                urls[fileMapping.fileId] = URL.createObjectURL(e);
              });
          } catch (err) {
            console.error(`Failed to fetch URL for fileId: ${fileMapping.fileId}`, err);
          }
        }
      }
    }
    setFileUrls((prev) => ({ ...prev, ...urls }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles([...selectedFiles, ...Array.from(event.target.files)]);
    }
  };

  const handleRemoveFile = (fileIndex: number) => {
    const updatedFiles = selectedFiles.filter((_, index) => index !== fileIndex);
    setSelectedFiles(updatedFiles);
  };

  const isImageFile = (fileName: string): boolean => {
    // Check file extensions for common image types
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    const extension = fileName.split(".").pop()?.toLowerCase();
    return extension ? imageExtensions.includes(extension) : false;
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() || selectedFiles.length > 0) {
      await sendMessage(ticketId);
    }
  };

  const sendMessage = async (ticketId: any) => {
    setLoading(true);

    const body = {
      TicketId: ticketId,
      Message: newMessage,
    };

    const res = await postDataWithFiles("ticketMessages", body, selectedFiles);

    if (res.ok) {
      setNewMessage("");
      setSelectedFiles([]);
      // setFileUrls({});
      await fetchTicket();

      // handleClose();
    } else {
      toast.error("Could not create ticket. Please try again");
    }

    setLoading(false);
  };

  return (
    <>
      <Button variant="primary" size="sm" onClick={handleShow}>
        {loading ? <Spinner animation="grow" size="sm" /> : "View"}
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          {ticket && (
            <Modal.Title>
              #{ticket.ticketNumber.toString().padStart(3, "0")} {ticket.title} - {moment.utc(ticket.dateCreated).local().format("DD MMM yyyy HH:mm")}
              <div style={{ marginTop: "5px" }}>
                <TicketStatusTag status={ticket.status} />
              </div>
            </Modal.Title>
          )}
        </Modal.Header>
        {ticket && (
          <Modal.Body>
            {(context as any).User?.roles.includes("SuperAdministrators") && (
              <Form.Group as={Col} sm={6}>
                <Select
                  id="ticketStatusSelect"
                  options={selectOptions}
                  value={
                    selectOptions.find((option: any) => option.value === ticket.status) || {
                      label: "New",
                      value: 0,
                    }
                  }
                  onChange={(e: any) => {
                    setField(e.value, "status");
                  }}
                  placeholder="Please select a status"
                />
              </Form.Group>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "500px",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              {/* Chat Box */}
              <div
                style={{
                  flex: 1,
                  padding: "10px",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                {ticket.ticketMessages.map((message) => (
                  <div
                    key={message.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: message.sentById === (context as any).User?.id ? "flex-end" : "flex-start",
                    }}
                  >
                    {/* Message Content */}
                    <div
                      style={{
                        backgroundColor: message.sentById === (context as any).User?.id ? "#007bff" : "#f1f1f1",
                        color: message.sentById === (context as any).User?.id ? "#fff" : "#000",
                        padding: "10px",
                        borderRadius: "12px",
                        maxWidth: "70%",
                      }}
                    >
                      <p style={{ margin: 0 }}>{message.message}</p>
                    </div>

                    {/* Attachments Footer */}
                    {message.messageFileMappings.length > 0 && (
                      <div
                        style={{
                          marginTop: "8px",
                          padding: "8px 10px",
                          backgroundColor: "#f9f9f9",
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          maxWidth: "70%",
                        }}
                      >
                        {message.messageFileMappings.map((fileMapping) => {
                          const fileUrl = fileUrls[fileMapping.fileId];
                          if (!fileUrl) {
                            return (
                              <span
                                key={fileMapping.fileId}
                                style={{
                                  fontSize: "0.85em",
                                  color: "#999",
                                }}
                              >
                                Loading file...
                              </span>
                            );
                          }

                          const isImage = isImageFile(fileMapping.file.fileName);

                          return isImage ? (
                            <img
                              key={fileMapping.fileId}
                              src={fileUrl}
                              alt={fileMapping.file.fileName}
                              className="mx-1"
                              onClick={() => window.open(fileUrl, "_blank")}
                              style={{
                                maxWidth: "100%",
                                // marginRight: "5px",
                                maxHeight: "150px",
                                borderRadius: "8px",
                                cursor: "pointer",
                                marginBottom: "5px",
                              }}
                            />
                          ) : (
                            <a
                              key={fileMapping.fileId}
                              href={fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                display: "block",
                                fontSize: "0.85em",
                                color: "#007bff",
                                textDecoration: "underline",
                              }}
                            >
                              {fileMapping.file.fileName}
                            </a>
                          );
                        })}
                      </div>
                    )}

                    {/* Sender Name */}
                    <small
                      style={{
                        marginTop: "5px",
                        color: "#888",
                        fontSize: "0.85em",
                        alignSelf: message.sentById === (context as any).User?.id ? "flex-end" : "flex-start",
                      }}
                    >
                      {message.sentByName} - {moment.utc(message.dateSent).local().format("HH:mm")}
                    </small>
                  </div>
                ))}
              </div>

              {/* Message Input */}
              <div style={{ borderTop: "1px solid #ddd", padding: "10px" }}>
                {/* Selected Files Preview */}
                {selectedFiles.length > 0 && (
                  <div style={{ marginBottom: "10px", display: "flex", flexWrap: "wrap", gap: "10px" }}>
                    {selectedFiles.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          border: "1px solid #ddd",
                          borderRadius: "8px",
                          padding: "5px",
                          maxWidth: "100px",
                          textAlign: "center",
                        }}
                      >
                        {isImageFile(file.name) ? (
                          <img
                            src={URL.createObjectURL(file)}
                            onClick={() => window.open(URL.createObjectURL(file), "_blank")}
                            alt={file.name}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "150px",
                              borderRadius: "8px",
                              cursor: "pointer",
                              marginBottom: "5px",
                            }}
                          />
                        ) : (
                          <a
                            href={URL.createObjectURL(file)}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: "0.75em",
                              display: "flex", // Flexbox for centering
                              justifyContent: "center", // Horizontally center the text
                              alignItems: "center", // Vertically center the text
                              height: "100%", // Ensure it fills the parent container
                              wordWrap: "break-word", // Handle long words
                              textAlign: "center", // Align text in the center horizontally
                            }}
                          >
                            {file.name}
                          </a>
                        )}
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleRemoveFile(index)}
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            transform: "translate(50%, -50%)",
                            padding: "2px 5px",
                          }}
                        >
                          x
                        </Button>
                      </div>
                    ))}
                  </div>
                )}

                <InputGroup>
                  {/* File Input */}
                  <Button title="Upload Files" variant="secondary" onClick={() => (hiddenFileInput as any).current.click()}>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </Button>
                  <Form.Control type="file" multiple onChange={handleFileChange} ref={hiddenFileInput} hidden />
                  {/* Message Input */}
                  <Form.Control
                    type="text"
                    placeholder="Type a message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e: any) => {
                      if (e.key === "Enter") handleSendMessage();
                    }}
                  />
                  {/* Send Button */}
                  <Button variant="primary" onClick={handleSendMessage} disabled={newMessage === "" || loading}>
                    {loading ? <Spinner animation="grow" size="sm" /> : "Send"}
                  </Button>
                </InputGroup>
              </div>
            </div>
          </Modal.Body>
        )}
        {context && (context as any).User && (context as any).User?.roles?.includes("SuperAdministrators") && edited && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={updateTicketStatus} disabled={loading}>
              {loading ? <Spinner animation="grow" size="sm" /> : "Save Changes"}
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
