import { Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../helpers/Contexts";
import CreateTicketModal from "../modals/createTicketModal";
import { IPagedViewModel } from "../entities/PagedModel";
import { ITicketSlimViewModel } from "../entities/Ticket";
import PaginationHelper from "../helpers/PaginationHelper";
import TicketStatusTag from "../helpers/ticketStatusBadge";
import ViewTicketModal from "../modals/viewTicketModal";
import { getData } from "../helpers/Data";
import moment from "moment";

export default function Support({ history, location }: { history: any; location: any }) {
  const [tickets, setTickets] = useState<IPagedViewModel<ITicketSlimViewModel>>({
    items: [],
    page: 0,
    pageCount: 0,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const getTickets = async () => {
    setLoading(true);
    const res = await getData("tickets/paged", { page: page, pageSize: pageSize, companyId: (context.selectedCompany as any)?.id });
    setTickets(res);
    setLoading(false);
  };

  const pageClick = (number: number) => {
    setPage(number);
  };

  useEffect(() => {
    getTickets();
  }, [page]);

  return (
    <div>
      <Card style={{ width: "100%" }}>
        <Card.Header>
          <Card.Title>
            Support Tickets
            {!(context as any).User?.roles.includes("SuperAdministrators") && (
              <span className="float-right">
                <CreateTicketModal getTickets={getTickets} />
              </span>
            )}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Table striped hover size="sm">
                <thead>
                  <tr>
                    <th>Ticket Number</th>
                    <th>Status</th>
                    <th>Date Created</th>
                    <th>Title</th>
                    <th>Requested By</th>
                    <th>Assigned To</th>
                    <th style={{ width: "230px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={8}>
                        <Spinner className="ml-auto mr-auto d-block" animation="grow" />
                      </td>
                    </tr>
                  ) : (
                    tickets.items.length > 0 &&
                    tickets.items.map((ticket: ITicketSlimViewModel, index) => {
                      return (
                        <tr key={index}>
                          <td>#{ticket.ticketNumber.toString().padStart(3, "0")}</td>
                          <td>
                            <TicketStatusTag status={ticket.status} />
                          </td>
                          <td>{moment.utc(ticket.dateCreated).local().format("DD MMM yyyy HH:mm")}</td>
                          <td>{ticket.title}</td>
                          <td>{ticket.requestedByName}</td>
                          <td>{ticket.assignedToName ?? ""}</td>

                          <td>
                            <ViewTicketModal location={location} history={history} ticketId={ticket.id ?? 0} getTickets={getTickets} />
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              {tickets.total > 0 ? <PaginationHelper page={tickets.page} pageCount={tickets.pageCount} total={tickets.total} pageCallback={pageClick} /> : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
