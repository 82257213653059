import { useContext, useEffect, useState } from "react";

import { AuthContext } from "./Contexts";
import { Badge } from "react-bootstrap";
import { getData } from "./Data";

export default function NewTicketBadge() {
  const context = useContext(AuthContext);
  const [newTickets, setNewTickets] = useState(0);

  const getNewTicketCount = async () => {
    if (context?.selectedCompany) {
      const res = await getData("tickets/newCount", { companyId: (context?.selectedCompany as any)?.id });

      setNewTickets(res);
    }
  };

  useEffect(() => {
    getNewTicketCount();
  }, [context?.selectedCompany]);

  return newTickets > 0 ? (
    <Badge className="ml-1" variant="primary">
      {newTickets}
    </Badge>
  ) : (
    <></>
  );
}
