import { ITicketMessageListViewModel } from "./TicketMessage";

export enum ETicketStatus {
  New,
  Open,
  PendingCustomer,
  InProgress,
  Closed,
  Cancelled,
}

export interface ITicketSlimViewModel {
  assignedToId?: string | null;
  assignedToName?: string | null;
  companyName: string;
  dateCreated: string;
  id?: number | null;
  requestedById: string;
  requestedByName: string;
  status: ETicketStatus;
  ticketNumber: number;
  title: string;
}

export interface ITicketListViewModel extends ITicketSlimViewModel {
  companyId: string;
  ticketMessages: ITicketMessageListViewModel[];
}

export function createBlankListViewTicket(): ITicketListViewModel {
  return {
    assignedToId: "",
    assignedToName: "",
    companyId: "",
    companyName: "",
    dateCreated: "",
    id: 0,
    requestedById: "",
    requestedByName: "",
    status: ETicketStatus.New,
    ticketMessages: [],
    ticketNumber: 0,
    title: "",
  };
}
